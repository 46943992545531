import {CstImageformats} from '../interfaces/entity/CstImageformats'
import {sendRequest} from 'sfportal_services_api/superagent'

const imageformats: Record<string, CstImageformats | Promise<CstImageformats>> = {}

/**
 * Der Storage für Smimageformats
 * Lädt die Smimageformats einmalig und gibt allen Anfragen, welche vor der Auflösung des Promises kommen das jeweilige Promise zurück und setzt anschließend die richtige Entity auf den
 * jeweiligen Identifier
 *
 * @param {string} format das zu ladende Format
 * @returns {Promise<CstImageformats>} das geladene Smimageformats
 */
export const getSmimageformatsFromStore = async (format: string): Promise<CstImageformats> => {
    if (imageformats[format]) {
        return imageformats[format]
    }

    /**
     * Hier kein await einbauen! - Da sonst bei Folgerequests neue Rest-Calls gesendet werden, wenn der 1te Promise noch nicht aufgelöst wurde!
     */
    imageformats[format] = sendRequest('post',
        `/cstimages/format`,
        {
            body: JSON.stringify([{
                'field': 'identifier',
                'operator': '=',
                'value': format
            }])
        }
    ).then(result => {
        imageformats[format] = result.body
        return result.body
    })

    return imageformats[format]
}
