import React, {FC, useCallback, useEffect, useState} from 'react'
import {sendRequest} from 'sfportal_services_api/superagent'
import {Cstassetcomments} from '../../interfaces/entity/CstAssetComments'
import {SingleComment} from './SingleComment'
import '../../sass/components/comments/CstComments.scss'
import {FormInput} from 'sfportal_components_forms/FormInput'
import {Button} from 'sfportal_components_forms/Button'
import {useTranslation} from 'react-i18next'
import {useSubscription} from 'sfportal_root/hooks/useSubscription'
import {useAlert} from 'react-alert'

/**
 * Type for the comments tab {@link CstComments}
 */
type Props = {
    /**
     * the content id
     */
    contentId: string
    /**
     * the dataresource id
     */
    dataresourceId: string
}

/**
 * The Comments Tab
 * @param {Props} props the props
 * @returns {FC<Props>} the Comment Tab
 */
export const CstComments: FC<Props> = (
    {
    contentId,
    dataresourceId
    }
    ) => {
    const {t} = useTranslation()
    const alert = useAlert()

    const [comments, setComments] = useState<Cstassetcomments[]>([])
    const [newComment, setNewComment] = useState<string>('')
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

    useEffect(() => {

        sendRequest(
            'get',
            '/cstassetcomments/assetcommentsModalEntries',
            {query: {contentId, dataresourceId}})
            .then(response => {
                setComments(JSON.parse(response.text))
            })
    }, [contentId, dataresourceId])

    /**
     * callback on the create sub. Sends and rest call for the cstassetcomment.
     */
    const createCallback = useCallback((message) => {
        const body = JSON.parse(message.body)
        sendRequest(
            'get',
            `/cstassetcomments/${body.id}`
        ).then(response => {
            const comment = response.body

            if (contentId === comment.contentId.toString() && dataresourceId === comment.dataresourceId.toString()) {
                setComments(current => ([
                    comment,
                    ...current
                ]))
            }
            })

    }, [contentId, dataresourceId])

    useSubscription('cstassetcomments', 'create', '*', createCallback)

    /**
     * Function that sends the comment for creation
     */
    const onSubmit = async () : Promise<void> => {
        if (newComment.length === 0){
            alert.error(t('cst.global.cstcomments.error'))
            return
        }
        setIsSubmitted(true)
        sendRequest(
            'post',
            '/cstassetcomments',
            {body: {contentId, dataresourceId, comment: newComment}}
        )
            .then(()=>setNewComment(''))
            .catch(()=>alert.error(t('request.status.badRequest.long')))
            .finally(()=>{
                setIsSubmitted(false)
            })
    }

    return <div className={'cstcomments-tab'}>
        <div className={'cstcomments-container'}>
            {comments.map(single => <SingleComment key={single.id} entry={single} showSeparator={true} />)}
        </div>
        <div className={'cstcomments-separator'} />
        <div className={'cstcomments-form-wrapper'}>
            <FormInput type={'textarea'}
            readonly={false}
            defaultValue={newComment}
            label={t('cst.global.cstcomments.label')}
            name={'comment'}
            onChange={(_, val: string) => setNewComment(val)} />
        <div className={'form-buttons'}>
            <Button buttonStyle={'secondary'} onClick={()=>setNewComment('')} disabled={isSubmitted}>{t('common.cancel')}</Button>
            <Button onClick={onSubmit} disabled={isSubmitted}>{t('global.save')}</Button>
        </div>
        </div>
    </div>
}
