import i18next from 'i18next'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
const dateTimeFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timeZone
}
export const dateFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timeZone
}
const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timeZone
}

/**
 * Wandelt ein Date zu einem Datum um mit Berücksichtigung von dem Language Key
 *
 * @param {Date} date das Date
 * @returns {string} das Datum
 */
export const getDate = (date: Date): string => {
    const locale = i18next.language
    return date.toLocaleDateString(locale, dateFormat)
}

/**
 * Wandelt ein Date zu einem Datum mit Uhrzeit um mit berücksichtigung von dem Language Key
 *
 * @param {Date} date das Date
 * @returns {string} das Datum mit Uhrzeit
 */
export const getDateTime = (date: Date): string => {
    const locale = i18next.language
    return date.toLocaleString(locale, dateTimeFormat)
}

/**
 * Wandelt ein Date zu einer Uhrzeit um mit berücksichtigung von dem Language Key
 *
 * @param {Date} date das Date
 * @returns {string} die Uhrzeit
 */
export const getTime = (date: Date): string => {
    const locale = i18next.language
    return date.toLocaleTimeString(locale, timeFormat)
}
