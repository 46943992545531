import {useEffect, useRef, useState} from 'react'
import {CstUsers} from '../interfaces/entity/CstUsers'
import {v4 as uuidv4} from 'uuid'
import {sendRequest} from 'sfportal_services_api/superagent'

const userStore: Record<number, CstUsers> = {}

const userInfo: Record<number, string> = {}

const userCallbacks: Record<number, Record<string, () => void>> = {}

const setUserStore = (userId: number, user: CstUsers): void => {
    userStore[userId] = user
    Object.values(userCallbacks[userId])?.forEach(single => single())
    delete userInfo[userId]
}

const addUserCallback = (userId: number, callbackId: string, callback: () => void): void => {
    if (!userCallbacks[userId]) {
        userCallbacks[userId] = {}
    }
    userCallbacks[userId][callbackId] = callback
}

const removeUserCallback = (userId: number, callbackId: string): void => {
    if (userCallbacks[userId]?.[callbackId] === undefined) {
        return
    }
    delete userCallbacks[userId][callbackId]
}

/**
 * Ein User Store mit Subscription
 *
 * @param {number} userId die User Id
 * @returns {CstUsers | undefined} der User oder undefined
 */
export const useUser = (
    userId: number
): CstUsers | undefined => {
    const [user, setUser] = useState<CstUsers | undefined>(undefined)
    const callbackId = useRef(uuidv4()).current

    useEffect(() => {
        if (userId === undefined || userId === null) {
            setUser(undefined)
            return
        }

        addUserCallback(userId, callbackId, () => setUser(userStore[userId]))

        if (userStore[userId] === undefined && userInfo[userId] === 'pending') {
            return
        }
        if (userStore[userId] === undefined) {
            userInfo[userId] = 'pending'
            sendRequest(
                'get',
                `/cstusers/${userId}`
            ).then(result => {
                setUserStore(userId, result.body)
            })
            return
        }
        setUser(userStore[userId])
        return () => {
            removeUserCallback(userId, callbackId)
        }
    }, [callbackId, userId])

    return user
}
