import { mdiAccountEdit, mdiAccountMultiple, mdiCheckOutline } from '@mdi/js'

/**
 * Icon, welches als Claimtask angezeigt wird.
 */
export const CLAIM_TASK_ICON = mdiAccountEdit

/**
 * Icon, welches fürs Task zurückgeben angezeigt wird.
 */
export const STOP_TASK_ICON = mdiAccountMultiple

/**
 * Icon, welches fürs abschließen von Task angezeigt wird.
 */
export const COMPLETE_TASK_ICON = mdiCheckOutline
