import React from 'react'
import { SubviewsRecord } from 'sfportal_components_generic/Subviews'
import { DocumentTreePreview } from './subviews/documentTreePreview/DocumentTreePreview'

export const documentTreePreviewSubview = 'documentTreePreview'

export function useGetProjectProductTreeDetailSubviews (): () => SubviewsRecord {
  return () => ({
    [documentTreePreviewSubview]: () => <DocumentTreePreview />
  })
}
