import { Response } from 'superagent'
import { sendRequest } from 'sfportal_services_api/superagent'

/**
 * Liefert alle Assets innerhalb eines Ordners.
 *
 * @param productId Die Id des Ordners, aus dem die Assets ausgelesen werden.
 * @param folderkey Der Key des Ordners, der ausgelesen werden soll.
 */
export async function apiGetFolderAssets (
  productId: number,
  folderkey: string
): Promise<Response> {
  return await sendRequest('get', 'folders/' + productId.toString() + '/' + folderkey)
}
