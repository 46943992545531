import { ApiProduct } from 'sfportal_services_api/apiSchemas'
import { LabelItem } from 'sfportal_components_badges/LabelItem'
import React, { FC, memo } from 'react'
import { Status } from 'sfportal_components_badges/Status'

/**
 * Die Properties für die Kompoenten innerhalb der Utils Klasse.
 */
interface Props {

  /**
   * Das Produkt, für welches die Metadaten / Status Label gerendert werden.
   */
  product: ApiProduct
}

/**
 * Komponente für Metadaten der einzelnen Produkte.
 */
export const MetadataRender = memo(function MetadataRender ({ product }: Props) {
  return <>
    <LabelItem label="Auflage">{product.edition?.toString()}</LabelItem>
    <LabelItem label="Verlag">{product.publisher}</LabelItem>
    <LabelItem label="Lektor">{product.lectorTeam}</LabelItem>
    <div className="list-item-product__status-col">
      <Status value={product.statusId}/>
    </div>
  </>
})

/**
 * Rendert den Status des Produkts.
 */
export const StatusRender = memo(function StatusRender (props: Props) {
  return null
})

/**
 * Komponente für Teams der einzelnen Produkte.
 */
export const Teams: FC<Props> = () => {
  return null
}
