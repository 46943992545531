import { mdiCalendarCheck, mdiFileTree, mdiFolderOutline, mdiMagnify } from '@mdi/js'
import React, { ReactElement, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppTabsItem } from '../../components/Layout/AppTabs'
import { useProductDetailStore } from '../../stores/productDetailStore'
import { ProductTreeDetail } from './productTree/detail/ProductTreeDetail'
import { ProductTree } from './productTree/list/ProductTree'
import {
  ProductTreeSubviewsContext,
  useProductTreeSubviewsContext
} from './productTree/list/useProductTreeContext'
import { ProductShares } from 'sfportal_views_productdetail_shares_list/ProductShares'
import { TasksDetail } from './tasks/detail/TasksDetail'
import { ProductTasks } from './tasks/list/ProductTasks'
import { ProductSearchTab } from './search/ProductSearchTab'

interface UseTabsResult {
  tabItemDetailViewMap: Map<AppTabsItem, ReactElement>
  visibleTabItems: AppTabsItem[]
  selectedDetailView: ReactElement | undefined
  handleTabChange: (index: number) => void
  currentTabIndex: number
}

/**
 * Liefert die Daten für die Tabs, die in der Produkt-Detail-Ansicht auf der
 * linken Seite angezeigt werden sollen, sowie die Komponente, die auf der
 * rechten Seite angezeigt werden soll. Letzteres wird durch den ausgewählten
 * Tab auf der linken Seite bestimmt.
 */
export function useTabs (): UseTabsResult {
  const { t } = useTranslation()
  const { product, sharedFilesAvailable, tasks } = useProductDetailStore()
  const productTreeSubviewsContext = useProductTreeSubviewsContext()

  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const tabItemDetailViewMap = useMemo<Map<AppTabsItem, ReactElement>>(() => {
    if (product === null) return new Map()

    const map = new Map<AppTabsItem, ReactElement>()

    map.set(
      {
        identifier: 'tasks',
        tabText: t('tasks.terms.tasks'),
        tabIcon: mdiCalendarCheck,
        tabCounter: process.env.REACT_APP_SHOW_TASK_COUNT && process.env.REACT_APP_SHOW_TASK_COUNT === 'false' ? undefined : Object.keys(tasks).length,
        body: <ProductTasks />
      },
      <TasksDetail />
    )

    map.set(
      {
        identifier: 'shares',
        tabText: t('shares.terms.shares'),
        tabIcon: mdiFolderOutline,
        show: sharedFilesAvailable,
        body: <ProductShares />
      },
      <TasksDetail />
    )

    if (process.env.REACT_APP_ENABLE_PRODUCT_DETAIL_PRODUCT_TREE === 'true') {
      map.set(
        {
          identifier: 'productTree',
          tabText: t('productTree.terms.productTree'),
          tabIcon: mdiFileTree,
          body: (
            <ProductTreeSubviewsContext.Provider
              value={productTreeSubviewsContext}
            >
              <ProductTree />
            </ProductTreeSubviewsContext.Provider>
          )
        },
        <ProductTreeSubviewsContext.Provider value={productTreeSubviewsContext}>
          <ProductTreeDetail />
        </ProductTreeSubviewsContext.Provider>
      )
    }

    if (process.env.REACT_APP_ENABLE_PRODUCT_DETAIL_PRODUCT_TREE_SEARCH === 'true') {
      const pageToSwitch = process.env.REACT_APP_ENABLE_PRODUCT_DETAIL_PRODUCT_TREE === 'true' ? 'productTree' : 'tasks'
      map.set(
        {
          identifier: 'search',
          tabText: t('products.search.tab'),
          tabIcon: mdiMagnify,
          body: <ProductSearchTab onSearchFound={() => setCurrentTabIndex(Object.values(visibleTabItems).findIndex(single => single.identifier === pageToSwitch))} />
        },
        <TasksDetail />
      )
    }

    return map
  }, [sharedFilesAvailable, product, productTreeSubviewsContext, t, tasks])

  const visibleTabItems = useMemo<AppTabsItem[]>(() => {
    return Array.from(tabItemDetailViewMap.keys()).filter(
      (item) => item.show !== false
    )
  }, [tabItemDetailViewMap])

  const selectedDetailView = useMemo<ReactElement | undefined>(() => {
    const currentTabItem = visibleTabItems[currentTabIndex]

    return tabItemDetailViewMap.get(currentTabItem)
  }, [currentTabIndex, tabItemDetailViewMap, visibleTabItems])

  function handleTabChange (index: number): void {
    setCurrentTabIndex(index)
  }

  return {
    tabItemDetailViewMap,
    visibleTabItems,
    selectedDetailView,
    handleTabChange,
    currentTabIndex
  }
}
