import React, {FC} from 'react'
import '../../sass/components/comments/CstComments.scss'
import {Cstassetcomments} from '../../interfaces/entity/CstAssetComments'
import {useTranslation} from 'react-i18next'
import {User} from '../general/User'
import {useGetDateString} from '../../hooks/useGetDateString'

/**
 * Interface for a single comment {@link SingleComment}
 */
interface Props {
    /**
     * the comment entry to use
     */
    entry: Cstassetcomments
    /**
     * If the separator should be shown
     */
    showSeparator: boolean
}

/**
 * Component for display a single comment
 *
 * @param {Props} props the props
 * @returns {FC<Props>} the single comment
 */
export const SingleComment: FC<Props> = (
    {
        entry,
        showSeparator
    }
) => {
    const { t } = useTranslation()
    const date = useGetDateString(entry.creadate)

    return <>
        <div className={'single-comment'} style={{display: 'flex', flexDirection: 'row'}}>
            <div className={'user-wrapper'}>
                <User userId={entry.creauser} showName={false} withAvatar={true} />
            </div>
            <div className={'comment-wrapper'}>
                <div style={{display: 'flex', flexDirection: 'row', fontWeight: 'bold'}} className={'single-comment-title'}>
                    <span style={{width: 'fit-content'}}>
                        <User userId={parseInt(entry.creauser.toString())} showName={true} withAvatar={false} />
                    </span>
                    <span>
                        {/*Bullet with whitespace before and after as HTML Code*/}
                        &nbsp;&#8226;&nbsp;
                    </span>
                    <span>{date}</span>
                </div>
                <div className={'single-comment-data'}>
                    <span className={'single-comment-info'}>
                    {entry.fksmworkflows
                        ? `${t('cst.global.cstcomments.prefix')} ${entry.workflowtaskName}`
                        : t('cst.global.cstcomments.singular')}
                    </span>
                    <span className={'single-comment-comment'}>
                        {entry.comment}
                    </span>
                </div>
            </div>
        </div>
        {showSeparator
            ? <hr className={'single-comment-separator'} />
            : null}
    </>
}
