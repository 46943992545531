import {useEffect, useState} from 'react'
import {getDate, getDateTime, getTime} from '../helper/DateUtils'


export type DateTargetFormat = 'date' | 'time' | 'datetime'

/**
 * Liefert einen lesbaren Datestring abhängig von der eingestellten local.
 *
 * @param {string} date Der input Datestring. Dieser muss mit new Date valide sein.
 * @param {boolean} isUTC Gibt an ob der inputDateString im UTC Format übergeben wurde.
 * @param {DateTargetFormat} targetFormat Das TargetFormat der Ausgabe.
 * @returns {string | null} Der lesbare Datestring oder null.
 */
export const useGetDateString = (date: string, isUTC = true, targetFormat: DateTargetFormat = 'datetime'): string | null => {

    const [displayString, setDisplayString] = useState<string | null>(null)

    useEffect(() => {
        const format = (convertDate: string): string => {
            const isUnix = typeof convertDate === 'number' || !convertDate.match(/[.:-]/)
            if (!isUnix && !convertDate.includes('Z') && isUTC && !convertDate.includes('+0') && (convertDate.includes(':') || convertDate.includes('.'))) {
                convertDate += 'Z'
            }
            const utcDate = new Date(convertDate)

            if (targetFormat === 'datetime') {
                return getDateTime(utcDate)
            }
            if (targetFormat === 'date') {
                return getDate(utcDate)
            }

            return getTime(utcDate)
        }

        setDisplayString(date ? format(date) : null)
    }, [date, isUTC, targetFormat])

    return displayString
}
