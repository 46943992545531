import React, {FC, useEffect, useRef, useState} from 'react'

import {useImage} from '../../hooks/useImage'
import {CstUsers} from '../../interfaces/entity/CstUsers'
import {v4 as uuidv4} from 'uuid'
import {getSmimageformatsFromStore} from '../../stores/SmimageformatsStorage'
import {ImageConfig, SmimageFormatsIdentifier} from '../../interfaces/entity/CstImageformats'
import {urlGetThumbnail} from '../../helper/UrlHelper'
import {MdiIcon} from '../../../components/Generic/MdiIcon'
import {mdiAccount} from '@mdi/js'

type ImageType = 'icon' | 'image'

interface Props {
    format?: SmimageFormatsIdentifier
    size?: number
}

interface GravatarProps extends Props {
    user: CstUsers
    imageId?: never
}

interface ImageProps extends Props {
    user?: never
    imageId: number
}

type UiImageProps = GravatarProps | ImageProps

/**
 * Eine Image Komponente, welche ein User Foto/Gravatar/Icon anzeigen kann oder ein Normales Image im übergebenen Format
 *
 * @param {UiImageProps} props die Props
 * @param {UiImageProps['imageId']} props.imageId die Image Id
 * @param {UiImageProps['format']} props.format Optional, das zu verwendende Format
 * @param {UiImageProps['size']} props.size Optional, die zu verwendende Größe
 * @param {UiImageProps['user']} props.user der User
 * @returns {FC} Das Image
 */
export const UiImage: FC<UiImageProps> = (
    {
        imageId,
        format = 'square-crop-center-smaller',
        size = 30,
        user
    }
) => {

    const image = useImage(imageId ?? user?.fksmimages)
    const [imageType, setImageType] = useState<ImageType | 'gravatar'>('icon')

    //imgData enthält entweder die URL für das Image oder den Email hash für den Gravatar
    const [imageData, setImageData] = useState<string>()
    const [internalSize, setInternalSize] = useState<number>(size)

    const loadUUID = useRef<string>()


    /**
     * Liefert die Höhe und Breite des Bildes mit Hilfe der Image Config.
     *
     * @param {ImageConfig[]} imageConfig Die Config des Bildes.
     * @param {number} fallback Fallback, falls aus der Config kein Höhe und Breite ermittelt werden kann.
     * @returns {{ height: number, width: number }} die Werte
     */
    const getWidthHeight = (imageConfig: ImageConfig[], fallback: number): { height: number, width: number } => {
        if (imageConfig[0]?.resizeimage) {
            return {
                width: imageConfig[0].resizeimage.width,
                height: imageConfig[0].resizeimage.height
            }
        } else if (imageConfig[0]?.cropresizeimage) {
            return {
                width: imageConfig[0].cropresizeimage.width,
                height: imageConfig[0].cropresizeimage.height
            }
        }

        return {
            width: fallback,
            height: fallback
        }
    }

    useEffect(() => {
        if (size === undefined) {
            return
        }
        setInternalSize(size)
    }, [size])


    useEffect(() => {
        const uuid = loadUUID.current
        if (image === undefined && user === undefined) {
            return
        }
        if (image) {
            loadUUID.current = uuidv4()
            setImageData(urlGetThumbnail(format, image.fileinfo!, true, false))
            setImageType('image')
            return
        }

        //Icon wird angezeigt falls kein User definiert ist oder Image geladen wurde
        getSmimageformatsFromStore(format).then(imgFormat => {
            //Wenn die uuid durch die anderen 2 cases gesetzt wurde, returnen
            if (uuid !== loadUUID.current) {
                return
            }
            const imageConfig = JSON.parse(imgFormat.config)
            const {height, width} = getWidthHeight(imageConfig, size)
            const tempSize = height > width ? width : height || size
            setImageType('icon')
            setInternalSize(parseInt(((tempSize * 0.8) / 2).toString(), 10) * 2)
        })
    }, [user, format, image, size])


    switch (imageType) {
        case 'image':
            return <img src={imageData} alt={''} />
        case 'gravatar':
            return <img src={`//www.gravatar.com/avatar/${imageData}?s=${size}&d=mp`} alt={''} />
        default:
            return <MdiIcon path={mdiAccount}/>
    }
}
