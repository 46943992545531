import { LabelItem } from 'sfportal_components_badges/LabelItem'
import React, { memo, ReactElement } from 'react'
import { Status } from 'sfportal_components_badges/Status'
import { mdiFileDocumentMultiple } from '@mdi/js'
import { ContentSection } from 'sfportal_components_generic/ContentSection'
import { IProductDetailViewUtils, Props, ContentSectionProps } from 'sfportal_views_productdetail_interfaces/IProductDetailViewUtils'
import { ListItemInformation } from '../ListItemInformation'
import { apiGetFolderAssets } from '../../../services/api/folderApiService'
import { ApiProduct, ApiTaskDataResourceAsset } from 'sfportal_services_api/apiSchemas'
import { Teams } from '../../../components/ListItem/utils/listitemproduct/ListItemProductUtils'

/**
 * Komponente um Metadaten zu Rendern.
 */
function MetadataDetailsRender ({ product }: Props): ReactElement {
  return <>
    <LabelItem label="Auflage">{product.edition?.toString()}</LabelItem>
    <LabelItem label="Verlag">{product.publisher}</LabelItem>
    <LabelItem label="Lektor">{product.lectorTeam}</LabelItem>
    <Status label="Status" value={product.statusId}/>
  </>
}

/**
 * Komponente um den Inhalt der Produktseite zu rendern.
 */
function ContentSectionProductDetail ({ children }: ContentSectionProps): ReactElement {
  return <>{children}</>
}

/**
 * Gibt an, ob weitere custom Content gerendert werden soll.
 */
async function additionalContentSectionsRequirement (product: ApiProduct | null): Promise<boolean> {
  if (product === null) {
    return false
  }
  const result = await apiGetFolderAssets(product.id, 'authorinformation')
  return (result.body as ApiTaskDataResourceAsset[]).length > 0
}

/**
 * Komponente zur Darstellung weiterer Produktdetail Elemente. Wird nur gerendert, wenn die additional Prüfung true ist.
 *
 * @param product Das Product.
 * @constructor Funktion Komponente.
 */
const AdditionalContentSections = memo(function AdditionalContentSections ({ product }: Props) {
  return <ContentSection title="Unterlagen"
    icon={mdiFileDocumentMultiple}>
    <ListItemInformation product={product}/>
  </ContentSection>
})

export const ProductDetailViewUtils: IProductDetailViewUtils = {
  MetadataDetailsRender: MetadataDetailsRender,
  ContentSectionProductDetail: ContentSectionProductDetail,
  additionalContentSectionsRequirement: additionalContentSectionsRequirement,
  AdditionalContentSections: AdditionalContentSections,
  TeamsRender: Teams
}
