import { mdiArrowLeft } from '@mdi/js'
import {
  loadDocumentTree,
  useDocumentTreeStore
} from 'chbeckportal_stores/documentTreeStore'
import React, { FC, useContext, useEffect, useMemo } from 'react'
import { Button } from 'sfportal_components_forms/Button'
import { useProductTreeStore } from 'sfportal_stores/productTreeStore'
import { noop } from 'sfportal_utils/function'
import { ProductTreeSubviewsContext } from 'sfportal_views/ProductDetail/productTree/list/useProductTreeContext'
import { documentTreePreviewSubview } from '../../../detail/useGetProjectProductTreeDetailSubviews'
import { DocumentTree } from './DocumentTree'

export const DocumentTreeSubview: FC = () => {
  const { setCurrentDetailSubview } = useContext(ProductTreeSubviewsContext)
  const { nodes } = useProductTreeStore()
  const { currentDocumentId, currentHeadingId } = useDocumentTreeStore()

  useEffect(() => {
    setCurrentDetailSubview(documentTreePreviewSubview)

    return () => {
      setCurrentDetailSubview(null)
    }
  }, [currentHeadingId, setCurrentDetailSubview])

  const currentDocument = useMemo(() => {
    if (currentDocumentId === null) return null
    return nodes[currentDocumentId]
  }, [currentDocumentId, nodes])

  const documentName = useMemo(
    () => currentDocument?.item.name ?? '-',
    [currentDocument]
  )

  function handleBackButtonClick (): void {
    loadDocumentTree(null).catch(noop)
  }

  return (
    <div className="document-tree">
      <div className="document-tree__toolbar">
        <Button
          onClick={handleBackButtonClick}
          icon={mdiArrowLeft}
          buttonStyle="inline"
        >
          Zurück
        </Button>

        <div className="document-tree__document-name">{documentName}</div>
      </div>

      <DocumentTree documentName={documentName} />
    </div>
  )
}
