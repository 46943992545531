import {useEffect, useRef, useState} from 'react'
import {CstExtendedImages} from '../interfaces/entity/CstImages'
import {v4 as uuidv4} from 'uuid'
import {sendRequest} from 'sfportal_services_api/superagent'

const imageStore: Record<number, CstExtendedImages> = {}

const imageInfo: Record<number, string> = {}

const imageCallbacks: Record<number, Record<string, () => void>> = {}

const setImageStore = (imageId: number, image: CstExtendedImages): void => {
    imageStore[imageId] = image
    Object.values(imageCallbacks[imageId])?.forEach(single => single())
    delete imageInfo[imageId]
}

const addImageCallback = (imageId: number, callbackId: string, callback: () => void): void => {
    if (!imageCallbacks[imageId]) {
        imageCallbacks[imageId] = {}
    }
    imageCallbacks[imageId][callbackId] = callback
}

const removeImageCallback = (imageId: number, callbackId: string): void => {
    if (imageCallbacks[imageId]?.[callbackId] === undefined) {
        return
    }
    delete imageCallbacks[imageId][callbackId]
}

/**
 * Ein Image Store mit Subscription
 *
 * @param {number} imageId die Image Id
 * @returns {CstExtendedImages | undefined} das Image oder undefined
 */
export const useImage = (
    imageId: number | null | undefined
): CstExtendedImages | undefined => {
    const [image, setImage] = useState<CstExtendedImages | undefined>(undefined)
    const callbackId = useRef(uuidv4()).current

    useEffect(() => {
        if (imageId === undefined || imageId === null) {
            setImage(undefined)
            return
        }

        addImageCallback(imageId, callbackId, () => setImage(imageStore[imageId]))

        if (imageStore[imageId] === undefined && imageInfo[imageId] === 'pending') {
            return
        }
        if (imageStore[imageId] === undefined) {
            imageInfo[imageId] = 'pending'
            sendRequest(
                'get',
                `/cstimages/${imageId}`,
            ).then(result => {
                setImageStore(imageId, result.body)
            })
            return
        }
        setImage(imageStore[imageId])
        return () => {
            removeImageCallback(imageId, callbackId)
        }
    }, [callbackId, imageId])

    return image
}
