import React from 'react'
import { SubviewsRecord } from 'sfportal_components_generic/Subviews'
import { DocumentTreeSubview } from './subviews/documentTree/DocumentTreeSubview'

export const documentTreeSubview = 'documentTree'

export function useGetProjectProductTreeListSubviews (): () => SubviewsRecord {
  return () => ({
    [documentTreeSubview]: () => <DocumentTreeSubview />
  })
}
