import { Response } from 'superagent'
import { sendRequest } from 'sfportal_services_api/superagent'
import { EMK } from 'sfportal_services_api/apiSchemas'

/**
 * Ändert die Mitautorenfreigabe eines Assets.
 *
 * @param entityMetaKey Der EntityMetaKey des Assets.
 */
export async function apiPutChangecoauthorreleaseEntityMetaKey (
  entityMetaKey: EMK
): Promise<Response> {
  return await sendRequest('put', 'taskassets/changecoauthorrelease/' + entityMetaKey)
}
