import {
  setCurrentHeadingId,
  toggleNodeOpenState,
  useDocumentTreeStore
} from 'chbeckportal_stores/documentTreeStore'
import React, {FC, useCallback, useMemo} from 'react'
import {
  ApiDocumentStructureElement,
  ApiDocumentStructureRoot,
  ApiTreeNode
} from 'sfportal_services_api/apiSchemas'
import { isDefined } from 'sfportal_utils/global'
import { createTreeList } from '../../../../../../../components/Tree/createTreeList'
import { FlatTreeNode, Tree } from '../../../../../../../components/Tree/Tree'
import { ChildrenProp } from '../../../../../../../jsx'
import './DocumentTree.scss'
import {useProductDetailStore} from 'sfportal_stores/productDetailStore'

interface Props extends ChildrenProp {
  documentName: string
}

export const DocumentTree: FC<Props> = ({ documentName }) => {
  const { nodes, openNodeIds, relations, currentHeadingId } =
    useDocumentTreeStore()
  const { search } = useProductDetailStore()

  const items = useMemo(() => {
    return createTreeList({ nodes, openNodeIds, relations })
  }, [nodes, openNodeIds, relations])

  const openNodes = useMemo(() => {
    return openNodeIds.map((id) => nodes[id]).filter(isDefined)
  }, [nodes, openNodeIds])

  function getRenderLabel (
    node: FlatTreeNode<unknown>
  ): string {
    const rootNode = node as FlatTreeNode<ApiDocumentStructureRoot>
    if (rootNode.item.id === 'documentRoot') {
      return documentName
    }

    const elementNode = node as FlatTreeNode<ApiDocumentStructureElement>
    return elementNode.item.heading
  }

  function handleItemClick (index: number): void {
    const node = items[index]
    setCurrentHeadingId(node.item.id)
  }

  function handleItemToggle (index: number, isOpen: boolean): void {
    toggleNodeOpenState(Object.values(items)[index].id, isOpen)
  }

  /**
   * function for checking the node recursively if the node or a children contains a search results
   *
   * @param {FlatTreeNode<unknown>} item the node
   * @param {string} metakey optional, specific metakey to search
   * @returns {boolean} if the node contains a child that equals a search result
   */
  const checkNode = useCallback((item: FlatTreeNode<unknown>, metakey?: string): boolean=>{
    const node = item as FlatTreeNode<ApiTreeNode>
    let foundFinding = false

    const check = (internItem:ApiTreeNode)=>{
      if (search?.results?.find(singleRes => `${singleRes.drid}|${singleRes.id}` === (metakey ?? internItem.entityMetaKey))){
        foundFinding = true
        return false
      }
      if (internItem?.nodeType === 'NODE' && internItem?.treenodes !== null){
        if (internItem.treenodes.every(check)){
          foundFinding = true
          return false
        }
      }
      return true
    }

    node.item.treenodes?.every(single=>{
      return check(single);
    })
    return foundFinding
  },[search])

  return (
    <Tree
      items={items}
      openNodes={openNodes}
      renderLabel={getRenderLabel}
      onItemClick={handleItemClick}
      onItemToggle={handleItemToggle}
      selectedItemId={currentHeadingId}
      searchActive={search !== null && search.results?.length !== 0}
      checkNodeCallback={checkNode}
    />
  )
}
