import { productDetailStoreMutationsAccess, productDetailStoreStateAccess } from 'sfportal_stores/productDetailStore'
import { ApiTask, EMK } from 'sfportal_services_api/apiSchemas'
import { apiPutChangecoauthorreleaseEntityMetaKey } from 'chbeckportal_services_api/taskassetsApiService'
import { ApiTaskDataresourceAssetCoAuthor } from 'chbeckportal_interfaces/ApiTaskInterfaces'
import { resetDocumentTreeStore } from '../documentTreeStore'
import { cloneDeep } from 'lodash'

// Erweiterung des productDetailStores aus dem Produkt.

/**
 * Ander die Mitautorenfreigabe eines Assets und updated die States im Store.
 *
 * @param entityMetaKey Der EntityMetakey des Assets.
 */
export async function changeCoAuthorReleaseCustom (
  entityMetaKey: EMK
): Promise<void> {
  await apiPutChangecoauthorreleaseEntityMetaKey(entityMetaKey)
  // Da der State im Store als Readonly nach außen gegeben werden kann, kann an dieser Stelle mit den
  // Tasks im entsprechenden StoreState gearbeitet werden. Readonly dient hier als Sicherheit, dass
  // niemand den State außerhalb der dafür vorgesehenen mutations ändert.
  productDetailStoreMutationsAccess.setTasks(
    Object.fromEntries(Object.entries(productDetailStoreStateAccess.tasks).map(([key, task]) => {
      let tempTask
      if (task.dataResource.id === entityMetaKey) {
        //clone the task, otherwise it is only an object reference and no rerender is triggered
        tempTask = cloneDeep(task);
        (tempTask.dataResource as ApiTaskDataresourceAssetCoAuthor).hasGrantedReleaseForCoAuthor =
        !(tempTask.dataResource as ApiTaskDataresourceAssetCoAuthor).hasGrantedReleaseForCoAuthor
      }
      return [key, tempTask ?? task]
    })
  ))
}

export function setTaskDiscardPending (task: ApiTask, discardPending: boolean): void {
  productDetailStoreMutationsAccess.setTaskDiscardPending(task, discardPending)
}

export function resetSubStores (): void {
  resetDocumentTreeStore()
}
