import {CstFileinfo} from '../interfaces/entity/CstFileinfo'

export function urlGetThumbnail(thumbnailFormatName: string, fileinfoObject: CstFileinfo, showDefault?: boolean, noCache?: boolean): string {
    let url = fileinfoObject?.storagemapping
        + 'smbothumbnaildata/'
        + thumbnailFormatName
        + fileinfoObject?.thumbfilepath
        + fileinfoObject?.filename

    if (showDefault !== false) { // Nur wenn showDefault explizit auf false gesetzt wurde
        url = addParam(url, 'showdefault', 'true')
    }
    if (noCache) {
        url = addParam(url, 'filehash', fileinfoObject.filehash)
    }

    return urlApi(url)
}
export function addParam(url: string, param: string | string[], value: string | number | boolean): string {
    const paramsTemp = []
    if (typeof param === 'string') {
        if (value) {
            paramsTemp.push(encodeURIComponent(param) + '=' + encodeURIComponent(value))
        } else {
            let tmp = param.split('&')
            for (let i = 0; i < tmp.length; i++) {
                paramsTemp.push(tmp[i])
            }
        }
    } else {
        for (const tmp in param) {
            if (param.hasOwnProperty(tmp)) {
                paramsTemp.push(encodeURIComponent(tmp) + '=' + encodeURIComponent(param[tmp]))
            }
        }
    }
    for (let i = 0; i < paramsTemp.length; i++) {
        url += (url.indexOf('?') >= 0 ? '&' : '?') + paramsTemp[i]
    }
    return url
}

export function urlApi(path: string): string {
    return urlClean([process.env.REACT_APP_API_BASE_URL, path].join('/'))
}

/**
 * Clean Function um den Pfad für die Verwendung vorzubereiten.
 *
 * @param path Der Pfad.
 */
function urlClean(path: string): string {
    return path.replace(/[\\\/]+/gm, '/')
}
