import { ApiTreeNode } from 'sfportal_services_api/apiSchemas'
import { FlatTreeNode } from '../../../../../components/Tree/Tree'
import { TreeNodeAction } from '../../../../../components/Tree/TreeNode'
import { useDocumentStructureAction } from './itemActions/useDocumentStructureAction'

type ProjectItemActions = (
  node: FlatTreeNode<ApiTreeNode>,
  productItemActions: TreeNodeAction[],
) => TreeNodeAction[]

export function useGetProjectItemActions (): ProjectItemActions {
  const { getDocumentStructureAction, showDocumentStructureAction } =
    useDocumentStructureAction()

  return (node, productItemActions) => {
    const actions: TreeNodeAction[] = []

    if (showDocumentStructureAction(node)) {
      actions.push(getDocumentStructureAction(node))
    }

    actions.push(...productItemActions)

    return actions
  }
}
