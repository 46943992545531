import React, { ReactElement, useMemo } from 'react'
import { ApiTaskDataResourceAsset } from 'sfportal_services_api/apiSchemas'
import { useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { ProductDetailViewUtilsDetail } from 'sfportal_views_productdetail_utils_detail/ProductDetailViewUtils.Detail'
import { ImagePreviewContent } from '../../../common/assetPreview/ImagePreviewContent'
import { FilePreviewContent } from 'sfportal_views_productdetail_tasks_detail/FilePreviewContent'
import {
  TaskMetadataDescriptors,
  FileTaskMetadataDescriptor,
  ImageTaskMetadataDescriptor
} from './TaskMetadataDescriptors'
import { useDetailPaths } from './useDetailPaths'
import { useImageMetadataDescriptors } from './useImageMetadataDescriptors'
import { useShouldShowImagePreview } from './useShouldShowImagePreview'
import { useTranslation } from 'react-i18next'

interface UsePreviewContentResult {
  previewContent: ReactElement | null
}

export function usePreviewContent (): UsePreviewContentResult {
  const { shouldShowImagePreview } = useShouldShowImagePreview()
  const { imagePath, filePreviewPath, correctionPreviewPath } = useDetailPaths()
  const { currentFile, currentFileMetadataStatus } = useProductDetailStore()

  const { t } = useTranslation()

  const imageMetadataDescriptors = useImageMetadataDescriptors()
  const assetMetadataDescriptors = ProductDetailViewUtilsDetail.getFileMetadataDescriptors(t)

  const showCorrectionPdfTab = useMemo<boolean>(() => {
    if (currentFile === null) return false

    const asset = currentFile as ApiTaskDataResourceAsset

    return (
      asset.assetPrivileges?.canUserViewCorrection &&
      correctionPreviewPath !== null
    )
  }, [correctionPreviewPath, currentFile])

  type PreviewType = 'asset' | 'image' | null

  const previewType = useMemo<PreviewType>(() => {
    if (currentFile === null) {
      return null
    }
    if (shouldShowImagePreview && imagePath !== null) {
      return 'image'
    }

    const withCorrectionPdf =
      showCorrectionPdfTab && correctionPreviewPath !== null
    const withoutCorrectionPdf = !showCorrectionPdfTab

    if (
      filePreviewPath !== null &&
      (withCorrectionPdf || withoutCorrectionPdf)
    ) {
      return 'asset'
    }
    return null
  }, [
    correctionPreviewPath,
    currentFile,
    filePreviewPath,
    imagePath,
    shouldShowImagePreview,
    showCorrectionPdfTab
  ])

  const metadataDescriptors = useMemo<TaskMetadataDescriptors | null>(() => {
    switch (previewType) {
      case 'asset':
        return assetMetadataDescriptors
      case 'image':
        return imageMetadataDescriptors
      default:
        return null
    }
  }, [assetMetadataDescriptors, imageMetadataDescriptors, previewType])

  const previewContent = useMemo(() => {
    if (currentFile === null) {
      return null
    }

    if (shouldShowImagePreview && imagePath !== null) {
      return (
        <ImagePreviewContent
          id={currentFile.id}
          imagePath={imagePath}
          metadata={metadataDescriptors as ImageTaskMetadataDescriptor[]}
          metadataStatus={currentFileMetadataStatus}
        />
      )
    }

    const withCorrectionPdf =
      showCorrectionPdfTab && correctionPreviewPath !== null
    const withoutCorrectionPdf = !showCorrectionPdfTab

    if (
      filePreviewPath !== null &&
      (withCorrectionPdf || withoutCorrectionPdf)
    ) {
      return (
        <FilePreviewContent
          filePreviewPath={filePreviewPath}
          id={currentFile.id}
          metadata={metadataDescriptors as FileTaskMetadataDescriptor[]}
          metadataStatus={currentFileMetadataStatus}
          showCorrectionPdfTab={showCorrectionPdfTab}
          correctionPreviewPath={correctionPreviewPath ?? undefined}
        />
      )
    }

    return null
  }, [
    currentFile,
    shouldShowImagePreview,
    imagePath,
    showCorrectionPdfTab,
    correctionPreviewPath,
    filePreviewPath,
    metadataDescriptors,
    currentFileMetadataStatus
  ])

  return { previewContent }
}
