import React, { FC } from 'react'
import './HtmlPreview.scss'

interface Props {
  title?: string
  content: string
}

export const HtmlPreview: FC<Props> = ({ title, content }) => {
  return (
    <div className="html-preview">
      {title !== undefined ? (
        <div className="html-preview__title">{title}</div>
      ) : null}

      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}
