import React from 'react'
import ReactDOM from 'react-dom'
import { IMenuItemUtils } from '../../../../../components/Menu/utils/interfaces/IMenuItemUtils'
import { apiHasLock } from '../../../../../services/api/filesApiService'
import { AlertManager } from 'react-alert'
import {HiddenIframe} from "../../../../../components/Generic/HiddenIframe";

/**
 * Handelt den Klick auf eine Menu Item, welches einen String offnen soll und keine Funktion ausführt. (z.B. DownloadUrl)
 *
 * @param action Der zu offnende String.
 * @param alert AlertManager zum pushen von Alerts ins System.
 */
async function handleMouseUpString (action: string, alert: AlertManager): Promise<void> {
  const urlSplit = action.split('/')
  return await new Promise((resolve, reject) => {
    apiHasLock(urlSplit[urlSplit.length - 1]).then(result => {
      if (result?.text === 'true') {
        alert.error('Das Dokument ist gesperrt')
        return reject(new Error('Document is blocked'))
      }

      // Iframe wird vorm erstellen immer leer gemacht, da sonst beim zweiten Upload die
      // Component bereits existiert und der Download nicht mehr getriggert wird.
      ReactDOM.render(
          <></>,
          document.getElementById('download-anchor')
      )
      ReactDOM.render(
          <HiddenIframe title={'downloadFrame'} src={action} />,
          document.getElementById('download-anchor')
      )
      return resolve()
    }).catch(() => { return reject(new Error('Document is blocked')) })
  })
}

/**
 * Utils mit Funktionen und Komponenten zu Menu Items.
 */
export const MenuItemUtils: IMenuItemUtils = {

  /**
   * Handelt den Klick auf eine Menu Item, welches einen String offnen soll und keine Funktion ausführt. (z.B. DownloadUrl)
   */
  handleMouseUpString: handleMouseUpString
}
