import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import './ContentSection.scss'
import { MdiIcon } from './MdiIcon'

interface Props extends ChildrenProp {
  title: string
  icon?: string
}

export const ContentSection = memo(function ContentSection ({
  children, title, icon
}: Props) {
  return (
    <div className="content-section">
      <div className="content-section__header">
        {icon !== undefined ? (
          <div className="content-section__icon-container">
            <MdiIcon path={icon} className="content-section__icon" />
          </div>
        ) : null}

        <div className="content-section__title">{title}</div>
      </div>

      <div className="content-section__body">{children}</div>
    </div>
  )
})
