import { mdiTextSearch } from '@mdi/js'
import { useDocumentTreeStore } from 'chbeckportal_stores/documentTreeStore'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyIndicator } from 'sfportal_components_generic/EmptyIndicator'
import { HtmlPreview } from 'sfportal_components_generic/HtmlPreview'

export const DocumentTreePreview: FC = () => {
  const { t } = useTranslation()
  const { nodes, currentHeadingId } = useDocumentTreeStore()

  const currentNode = useMemo(() => {
    return (
      Object.values(nodes).find((node) => node.item.id === currentHeadingId) ??
      null
    )
  }, [currentHeadingId, nodes])

  if (currentNode === null) {
    return (
      <EmptyIndicator
        text={t('documentStructure.detailView.empty')}
        icon={mdiTextSearch}
        compact
        stretch
        dimmed
      />
    )
  }

  return (
    <HtmlPreview
      title={currentNode.item.heading ?? undefined}
      content={currentNode.item.content}
    />
  )
}
