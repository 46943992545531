import React, {FC, useMemo} from 'react'
import {UiImage} from './UiImage'
import '../../sass/general/User.scss'
import {useUser} from '../../hooks/useUser'
import {SmimageFormatsIdentifier} from '../../interfaces/entity/CstImageformats'
import {useTranslation} from 'react-i18next'

interface Props {
    userId: number
    size?: number
    format?: SmimageFormatsIdentifier
    showName?: boolean
    withAvatar?: boolean
}

/**
 * Kopierte Komponente mit useUser Hook für die Darstellung eines Users in der Oberfläche.
 *
 * @param {Props} props die Props
 * @param {Props['userId']} props.userId Die Id des Users.
 * @param {Props['size']} props.size Die Größe der Darstellung.
 * @param {Props['format']} props.format Das Format des Bildes.
 * @param {Props['showName']} props.showName Gibt an ob der Name angezeigt werden soll.
 * @param {Props['withAvatar']} props.withAvatar Gibt an ob der Avatar angezeigt wird.
 * @returns {FC} die User Anzeige
 */
export const User: FC<Props> = (
    {
        userId,
        size = 30,
        format = 'square-crop-center-smaller',
        showName = true,
        withAvatar = true
    }) => {

    const user = useUser(userId)
    const {t} = useTranslation()

    const userData = useMemo(() => {
        if (user === undefined) {
            return {
                displayname: '-',
                tooltip: t('global.noUser')
            }
        }
        const tempData: { displayname?: string, tooltip?: string } = {}

        if (user.firstname && user.lastname) {
            tempData.displayname = `${user.firstname} ${user.lastname}`
        } else {
            tempData.displayname = user.username ?? user.email
        }

        if (!showName) {
            tempData.tooltip = tempData.displayname
        }

        return tempData
    }, [showName, user])

    return <span className={'sfui-user'} title={userData.tooltip}>
        {withAvatar
            ? <UiImage user={user!} size={size} format={format} />
            : null}
        {showName
            ? <div className={'sfui-ellipsis-div'}>
                {userData?.displayname}
            </div>
            : null}
        </span>
}
